.projectList-container {
  margin-top: 8px;
  .outer-box {
    padding-bottom: 24px;
  }
  .icon {
    width: 24px;
    height: 24px;
    padding: 0px;
    font-size: x-large;
    color: #666666;
  }
  .icon_down_array_status::before {
    font-size: x-large;
    color: #5029dd;
    padding:0px 16px 0px 0px;
  }


  .multi_select_dropdown {
    width: 244px;
    .MuiOutlinedInput-notchedOutline {
      // border: 1px solid #aaa;
    }
    
  }

  .input {
    display: flex;
    align-items: center;
    padding: 16px;
    max-width: 100%;
    .projectList-filter-section {
      display: flex;
      gap: 16px;
      align-items: center;
      // flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      .filter-box {
        width: 33%;
        .searchText {
          width: 100%;
          .MuiInputBase-root.MuiOutlinedInput-root {
            padding: 11px 16px;
          }
        }
      }
      .MuiFormControl-root.css-1nrlq1o-MuiFormControl-root, .status-box {
        width: 20%;
        position: relative;
        .multi_select_dropdown {
          width: 100%;
        }
        .anualField_1 {
          width: 100%;
          .css-11s1e64-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-input {
            padding: 11px 16px;
            cursor: pointer;
          }
        }
        .MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple {
          padding: 11px 16px;
        }
      }
      .filter-search-button {
        width: 48px;
        .btn-layout::before {
          padding: 0;
          width: 48px;
          height: 48px;      
        }
      }
    }

    .name-text {
      margin: 0 0 4px 0;
      width: max-content;
      margin-right: 16px;
      font-family: actualM;
    }

    ::placeholder {
      color: 'rgba(102, 102, 102, 1)';
      // padding-left: 24px;
      font-family: 'Actual';
      font-size: 14px;
      font-weight: 400;
      line-height: 17.5px;
      letter-spacing: 0.25px;
      text-align: left;

    }

    
  }

  input:focus {
    outline: none;
  }

  .no-result {
    height: 250px;
    width: 100%;
    margin: 20px;
    // border: 1px solid #aaa;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // background-color: #fff;
    p {
      font-family: actualM;
      font-size: 16px;
    }
    .back-projects {
      color: #007bff;
      text-decoration: underline;
      margin: 16px 0 0 0;
      cursor: pointer;
    }
  }

  .MuiDataGrid-main {
    background: #fff;
    overflow: auto;
    width: 100%;
    div:nth-of-type(2) {
      height: auto !important;
    }

    .MuiDataGrid-root .MuiDataGrid-row {
      font-family: "ActualR";
      font-weight: 400;
      font-size: 12px;
      color: #666666;
    }
    .MuiDataGrid-row:hover {
      background-color: rgba(244, 247, 253, 1) !important;
      cursor: pointer;
      font-family: "ActualM";
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0.25px;
      text-align: left;
      color: #333333;
    }
    .MuiDataGrid-columnHeaderTitle {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      background: #f1f1f1;
      font-family: "ActualM";
      color: #333333;
    }
    .MuiDataGrid-columnHeaders {
      background-color: #f1f1f1;
      height: 48px !important;
      min-height: 48px !important;
      max-height: 48px !important;
      line-height: 48px !important;
      .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader {
        height: 48px !important;
        min-height: 48px !important;
        max-height: 48px !important;
      }
    }

    .MuiDataGrid-iconSeparator {
      visibility: hidden;
    }
  }

  .MuiDataGrid-virtualScroller {
    overflow: auto !important;
    height: 100% !important;
    max-height: 900px !important;
    margin-top: 48px !important;
  }
  .MuiDataGrid-row:hover {
    background-color: rgba(244, 247, 253, 1) !important;
    cursor: pointer;
    font-family: "ActualR";
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.25px;
    text-align: left;
    color: #333333;
  }
  // .Mui-selected {
  //   background-color: var(--color-2) !important;
  // }

  // .MuiDataGrid-row.Mui-selected {
  //     background-color: rgba(25, 118, 210, 0.08) !important;
  // }

  .MuiDataGrid-toolbarContainer {
    justify-content: flex-end;

    button {
      color: var(--color-2);
    }
  }
  .files_link {
    color: #2266dd;
    text-decoration: underline !important;
  }
  .button-view {
    color: #fff;
    background: #48bc25;
    text-transform: unset !important;
    width: 84px;
    height: 40px;
    font-size: 13px;
    letter-spacing: 0.1px;
    font-family: "ActualR";
    box-shadow: none;
  }
  .button-view:hover {
    color: #fff;
    background: #48bc25;
    border: none;
    box-shadow: none;
  }
  .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  .MuiDataGrid-selectedRowCount {
    visibility: hidden;
  }

  .MuiDataGrid-cellContent {
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft,
  .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textRight,
  .MuiDataGrid-cell.MuiDataGrid-cell--textLeft,
  .MuiDataGrid-cell.MuiDataGrid-cell--textRight {
    justify-content: left;

    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .list-button-group {
    padding: 0px 20px 0 20px;
    display: flex;
    justify-content: space-between;
    button {
      margin-left: 10px;
    }
  }

  .reset-filter {
    margin-left: 10px;
    color: blue;
    cursor: pointer;
  }

  .MuiDataGrid-menuIcon {
    button {
      display: none;
    }
  }
}
.fileUpload {
  .MuiOutlinedInput-root.MuiInputBase-root {
    width: 400px;
    margin: 10px 0;
    .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input {
      padding: 10px 34px 10px 10px;
      // border: 1px solid grey;
    }
  }
}
.projectList-width {
  .MuiDialog-container.MuiDialog-scrollPaper {
    .MuiPaper-root-MuiDialog-paper,
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24 {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.MuiDialogContent-root {
  border-top: none !important;
}
.MuiTypography-root.MuiDialogTitle-root {
  height: 12px !important;
}
.MuiTypography-root.MuiDialogContentText-root {
  width: 100%;
  height: 100%;
}
i.pms-font.pms-sign {
  font-size: 24px;
  line-height: 1.15;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
i.pms-sign:before {
  color: var(--color-1);
}
.fileUpload-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  .uploadedImage {
    width: 100%;
    min-height: 100%;
    height: auto;
    border: 1px solid #aaaa;
    margin: auto;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    .display-imagefile {
      //  height: 100%;
      //  width:100%;
      display: flex;
      align-items: center;
      img {
        width: 100%;
        height: 70vh;
      }
    }
  }
}
.downloadItems {
  // width:100%;
  // height:650px;
  display: flex;
  align-items: center;
  flex-direction: column;
  object {
    height: 70vh;
    width: 70vw;
  }
  button {
    border: 2px solid #2e7d32;
    height: 32px;
    color: #2e7d32;
    background-color: white;
    font-size: 12px;
  }
}

.button-control {
  .MuiIconButton-sizeSmall:hover {
    background-color: transparent;

    button.file-list-action:hover {
      background-color: transparent;
      color: #1976d2;
    }

    button:focus {
      box-shadow: none;
    }
  }
}

.status-box {
  .anualField_1 {
    width: 244px;
    // cursor: pointer;
  }
  .status_options_list_main.active {
    visibility: visible;
    display: flex;
    flex-direction: column;
    background: #fff;
    border: 1px solid #aaa;
    width: 100%;
    margin-top: 2px;
    padding: 8px 4px 0px 8px;
    border-radius: 4px;
    z-index: 2;
    position: absolute;
    overflow: auto;
    height: 378px;
    .search_box {
      width: 100%;
      border: 1px solid #aaa;
      border-radius: 4px;
      height: 40px;
      .search_box:focus {
        border: 1px solid #aaa;
      }
    }
    input:focus {
      outline: none;
    }
    .project_type_main {
      display: flex;
      .status_checkbox_main {
        display: flex;
        margin-top: 8px;
      }
      .filter_prop_text {
        display: flex;
        font-size: 13px;
        font-family: "Actual";
      }
    }
    .select_project_type {
      font-size: 13px;
      font-family: "ActualR";
      color: #666666;
      display: flex;
      flex-direction: column;
      padding-left: 24px;
      .filter_checkbox {
        display: flex;
      }
    }
  }
  .status_options_list_main.inactive {
    visibility: hidden;
    display: flex;
    flex-direction: column;
    background: #fff;
    border: 1px solid #aaa;
    width: 238px;
    margin-top: 2px;
    padding: 8px 4px 0px 8px;
    border-radius: 4px;
    z-index: 2;
    position: absolute;
    overflow: auto;
    height: 378px;
    .project_type_main {
      display: flex;
      .status_checkbox_main {
        display: flex;
        margin-top: 8px;
      }
      .filter_prop_text {
        display: flex;
        font-size: 13px;
        font-family: "Actual";
      }
    }
    .select_project_type {
      font-size: 13px;
      font-family: "ActualR";
      color: #666666;
      display: flex;
      flex-direction: column;
      padding-left: 24px;
      .filter_checkbox {
        display: flex;
      }
    }
  }
}

.file-filter {
  display: flex;
  width: 100%;
  position: relative;
}

p.filter-label {
  font-family: ActualM;
  font-size: 13px;
  text-align: left;
}

.filter-search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  .btn-layout::before {
    color: #fff;
    background: #48bc25;
    text-transform: unset !important;
    width: 30px;
    height: 30px;
    font-size: 28px;
    padding: 5px;
    letter-spacing: 0.1px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .btn-layout:hover {
    background: #48bc25;
    border: none;
     border-radius: 4px;
    box-shadow: none;
    cursor: pointer;
  }
}

.filter-button {
  margin: 0px 0px 18px 32px;
  display: flex;
  border: 1px solid #aaa;
  border-radius: 4px;

  .filter-btn-layout {
    color: #0f0f0f;
    background: #fff;
    text-transform: unset !important;
    width: 48px;
    height: 48px;
    font-size: 28px;
    letter-spacing: 0.1px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .filter-btn-layout:hover {
    background: #fff;
    border: none;
    box-shadow: none;
    cursor: pointer;
  }
}

.filter_container {
  display: flex;
  justify-content: flex-start;
  height: 400px;
  background: white;
  max-width: 720px;
  border: 1px solid #aaa;
  margin-right: 8px;
  z-index: 2;
  width: 90%;
  position: absolute;
  right: 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  top: 90px;
  padding: 0px 20px;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
  overflow: auto;
  .title {
    font-size: 16px;
    font-family: "ActualM";
    color: #333333;
  }
  .div_main1 {
    display: flex;
    flex-direction: column;
    z-index: 1;
    background: #fff;
    position: sticky;
    top: 0;
    width: 100%;
    padding-top: 20px;

    .filter_prop {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .filter_prop_text {
        font-size: 13px;
        font-family: "ActualM";
      }
      .clear_text {
        font-size: 13px;
        font-family: "ActualM";
        color: #2266dd;
        cursor: pointer;
      }
    }
    .select_txt {
      font-size: 13px;
      font-family: "ActualM";
      margin-top: 8px;
      color: #666666;
    }
  }
  .div_main2 {
    display: flex;
    flex-direction: column;
    .project_type_main {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .filter_prop_text {
        font-size: 13px;
        font-family: "ActualM";
      }
    }
    .select_project_type {
      font-size: 13px;
      font-family: "ActualM";
      margin-top: 8px;
      color: #666666;
      display: flex;
      /* gap: 16px; */
      flex-wrap: wrap;
      display: grid;
      grid: auto / auto auto auto;
      grid-gap: 10px;
      /* background-color: #2196F3; */
      padding: 10px;
      .filter_checkbox {
        display: flex;
      }
    }
  }
  .div_main3 {
    display: flex;
    flex-direction: column;
    .project_type_main {
      display: flex;
      flex-direction: column;
      .status_checkbox_main {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
      }
      .filter_prop_text {
        font-size: 13px;
        font-family: "ActualM";
      }
    }
    .select_project_type {
      font-size: 13px;
      font-family: "ActualM";
      margin-top: 8px;
      color: #666666;
      display: flex;
      /* gap: 16px; */
      flex-wrap: wrap;
      display: grid;
      grid: auto / auto auto auto auto;
      grid-gap: 10px;
      /* background-color: #2196F3; */
      padding: 10px;
      .filter_checkbox {
        display: flex;
      }
    }
  }
  .div_main4 {
    display: flex;
    flex-direction: column;
    .project_type_main {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .filter_prop_text {
        font-size: 13px;
        font-family: "ActualM";
      }
    }
    .select_project_type {
      font-size: 13px;
      font-family: "ActualM";
      margin-top: 8px;
      color: #666666;
      display: flex;
      flex-wrap: wrap;
      display: grid;
      grid: auto / auto auto auto;
      grid-gap: 10px;
      padding: 10px;
      .filter_checkbox {
        display: flex;
      }
    }
  }
  .div_main5 {
    display: flex;
    justify-content: space-between;
    z-index: 1;
    background: #fff;
    position: sticky;
    bottom: 0;
    width: 100%;
    padding-bottom: 20px;
    box-sizing: border-box;
    border-top: 1px solid #aaa;
    padding-top: 20px;
    .filter_prop {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      .filter_prop_text {
        font-size: 13px;
        font-family: "ActualM";
      }
      .select_txt {
        font-size: 13px;
        font-family: "ActualM";
        color: #666666;
      }
    }
    .filter_button {
      .btn-layout {
        color: #fff;
        background: #48bc25;
        text-transform: unset !important;
        width: 224px;
        height: 40px;
        font-size: 13px;
        letter-spacing: 0.1px;
        font-family: "ActualR";
        box-shadow: none;
      }
      .btn-layout:hover {
        background: #48bc25;
        border: none;
        box-shadow: none;
      }
    }
  }
}

.MuiFormLabel-root-MuiInputLabel-root {
  padding: 12px 0px 0px 0px;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root .Mui-focused {
  color: #aaa;
}
.MuiList-root.MuiList-padding.MuiMenu-list {
  // padding: 16px 8px 8px 8px;
  max-height: 370px;
  width: 100%;
  height: 100%;
  padding: 0;
  .search_box {
    width: 95%;
    border: 1px solid #aaa;
    border-radius: 4px;
    height: 40px;
    padding-left: 6px;
    .search_box:focus {
      border: 1px solid #aaa;
    }
  }
  input:focus {
    outline: none;
  }
}
.MuiList-root.MuiList-padding.MuiMenu-list
  .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
  height: 40px !important;
  padding: 8px;
}

.filter_container::-webkit-scrollbar {
  width: 20px;
}

.filter_container::-webkit-scrollbar-track {
  background-color: transparent;
}

.filter_container::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.filter_container::-webkit-scrollbar-thumb:hover {
  background-color: #d6dee1;
}

.status_options_list_main::-webkit-scrollbar {
  width: 15px;
}

.status_options_list_main::-webkit-scrollbar-track {
  background-color: transparent;
}

.status_options_list_main::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.status_options_list_main::-webkit-scrollbar-thumb:hover {
  background-color: #d6dee1;
}

.MuiMenu-paper::-webkit-scrollbar {
  width: 15px;
}

.MuiMenu-paper::-webkit-scrollbar-track {
  background-color: transparent;
}

.MuiMenu-paper::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.MuiMenu-paper::-webkit-scrollbar-thumb:hover {
  background-color: #d6dee1;
}
