.modal-header{
    button{
        border: none;
        background: white;
        width: 20px;
        .sr-only{
            display: none;
        }
    }
}
.modal-footer{
    button{
        padding: 5px 10px;
        font-family: ActualR;
        font-size: 12px; 
    }
}

.MuiPaper-root-MuiDialog-paper {
    min-width: 400px;
    min-height: 200px;
}


.fullscreen-preview{
    background-color : var(--color-1) !important;
    box-shadow:none !important;
}

.MuiButtonBase-root.MuiButton-root:hover {
    text-decoration: none;
    // background-color: rgba(25, 118, 210, 0.04);
    // color: #1976d2 !important;
}

.cancel-popup{
    font-family:ActualB;
    cursor:pointer;
    font-size: x-large;
}
.MuiDialogTitle-root.dialog-title {
    background: #333333;  
    font-family: 'ActualR';
    font-size: 15px;
    color: #FFFFFF;
    // padding:24px;
}