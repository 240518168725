.MuiButtonBase-root.button-var1, .button-var1{
    color:white;
    background: var(--color-1);
    // background: var(--color-1);
    text-transform: capitalize;
}
.MuiButtonBase-root.button-var1:hover, .button-var1:hover{
    background: var(--color-1);
    color:white;
}
.MuiButtonBase-root.button-var2, .button-var2{
    color:white;
    background: var(--color-2);
    text-transform: unset;
}
.MuiButtonBase-root.button-var2:hover, .button-var2:hover{
    background: var(--color-2);
    color:white;
}

.MuiButtonBase-root.button-var3, .button-var3{
    color: var(--color-4);
    border:1px solid;
    text-transform: capitalize;
}

.MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .MuiButtonBase-root-MuiCheckbox-root.Mui-indeterminate{
    color:var(--color-1);
}
button.button-view, span.button-view, button.button-view:hover, span.button-view:hover{
    background-color: #ddd;
    color: black;
}
.button-var5_close{
    color: #2266DD !important;
    background: transparent;
    text-transform: unset;  
    border: none;
}
.button-var5_close:hover{
    color: #2266DD !important;
    background: transparent;
    text-transform: unset;  
}
.save-btn-var3{
    color: white;
        background: #48BC25;
        text-transform: unset;
        width: 170px;
        height: 46px;
        font-size: 14px;
        letter-spacing: 0.1px;
        font-family: 'ActualR';
        box-shadow: none;
}
.save-btn-var3:hover{
    color: white;
        background: #48BC25;
        text-transform: unset;
        width: 170px;
        height: 46px;
        font-size: 14px;
        letter-spacing: 0.1px;
        font-family: 'ActualR';
        box-shadow: none;
}