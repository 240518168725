.top-navigation{
    background: white;
    color:Black;

    .nav.nav-style{
      position:fixed;
      min-width: 22%;
      height:100%;
      background: #262b40;
      border-radius : 0 10px 10px 0;
      padding:20px;
  
      a{
          color:black;   
      }
      a.brand{
       font-size:26px;
      }
  
  }
  hr.divider {
    margin:10px 0;
      border-top : 1px solid black;
  }
  
   .navbar-brand {
    color:black;
    font-family:ActualM;
    font-size: 26px;
    display:flex;
    align-items:center;
    img{
      width:100px;
    }
    hr{
      width:0;
      height:32px;
      border:1px solid black;
      color:black;
      margin:0 10px;
      opacity:1;
     }

  }
  .navbar-brand:hover {
    color:black;
  }
    a , a:hover, a:active, .nav-link:focus{
    color:black;
    display:flex;
    align-items:center;
          .avatar{
            img{
              width: 30px;
              height:30px;
              border-radius: 50%;
              margin-right: 10px;
            }

          } 
    }  
 .dropdown-menu {
  height:auto !important;
  overflow:hidden !important;
  .dropdown-item{
    color:black;
  }
 }
 label {
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin-top:10px;
}
}
// label {
//     color: white;
//     font-weight: bold;
//     font-size: 18px;
//     margin-top:10px;
// }

.MuiAvatar-root{
  background-color: var(--color-1) !important;
}


@media only screen and (max-width: 480px){
.top-navigation{
  .navbar-brand {
    font-size: 14px;
    img{
      width:70px;
    }
    hr{
      height:25px;
    }
  }
}
}

