  .dropzone {
    text-align: center;
    padding: 40px 20px;
    // border: 1px #dee2e6 solid;
    width: 100%;
    margin: auto;
  }
  
  // .btn {
  //   border: none;
  //   text-align: center;
  //   background-color: rgb(218, 216, 216);
  //   height: 50px;
  //   border-radius: 12px;
  //   color: black;
  //   font-weight: bold;
  //   transition-duration: 0.6s;
  // }
  
  // .btn:hover {
  //   background-color: blue;
  //   color: aliceblue;
  // }
  
  .file-list {
    display: flex !important;
    flex-wrap: wrap;
    width: auto;
    padding: 10px 20px;
    margin: 20px 30px;
  }
  
  .file-list img {
    height: 100%;
    width: 100px;
    padding-right: 10px;
    object-fit: cover;
  }