.headerImpersonate {
    max-width: 1340px;
    margin: auto;
    width: 100%;
    padding: 24px 0px;
    .project-detail-account-component {
        background-color: #EEEEEE;
        display: flex;
        justify-content: space-between;

        #left {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .left_project {

            #color {
                margin-top: 8px;
                font-family: 'ActualR';
                font-style: normal;
                font-size: 12px;
                letter-spacing: 0.25px;

            }

        }

        #hr_tag {
            width: 0px;
            border-left: 1px solid #AAAAAA;
            height: 40px;
            margin: 0 24px;

        }

        .left_project p {
            margin-top: 4px;
        }
        .left_project p:first-child {
            margin-top: 0 !important;
        }

        .left_project h4 {
            font-family: 'ActualM';
            font-style: normal;
            font-size: 20px;
            letter-spacing: 0.25px;
            color: #212121;

        }

        .heading_name_style {
            font-family: 'ActualR';
            font-style: normal;
            font-size: 16px;
            letter-spacing: 0.15px;
            color: #232323;
            line-height: 20px;
            text-transform: capitalize;
        }

        .left_project p {
            font-family: 'ActualR';
            font-style: normal;
            font-size: 12px;
            letter-spacing: 0.25px;
            color: #666666;
        }

        #ins {
            margin-top: 4px;
            font-family: 'ActualR';
            font-style: normal;
            font-size: 14px;


            letter-spacing: 0.25px;


            color: #666666;
        }

        // #right_project {

        // // margin: 19px 0px 0px 0px
        // }
        .switch_betwwen_appto {
            border-radius: 4px;
            border: 1px solid #AAAAAA;
            margin-right: 13px;
            margin-top: 10px;
            height: 40px;

            .header {
                background-color: #212121;
                color: #fff;
                font-family: 'ActualR';
                font-size: 12px;
                text-align: center;
                padding: 10px 10px 0 10px;

            }

            .switch_toggler {
                display: flex;
                justify-content: center;
                padding: 10px;

                #switch {

                    .MuiSwitch-sizeMedium {
                        width: 48px;
                        height: 24px;
                        margin: 0px 0px 0px 0px;
                    }

                    .MuiSwitch-switchBase {
                        top: -1px;
                    }

                    .MuiFormControlLabel-root {
                        margin: 0px;
                    }

                    .MuiSwitch-switchBase.Mui-checked {
                        left: 7px;
                    }

                    .MuiTypography-root {
                        font-family: 'ActualR';
                        font-size: 14px;
                        color: #333333;
                    }

                    .Mui-checked {
                        color: #fff !important;

                    }

                    // .MuiSwitch-track {
                    //     background-color: #48BC25 !important;
                    // }
                    .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
                        background-color: #48BC25 !important;
                    }
                }

                .main-text_content {
                    font-family: 'ActualR';
                    font-size: 14px;
                }
            }
        }


        .MuiButtonBase-root.button-var2 {
            color: white;
            background: var(--color-2);
            text-transform: unset;
            max-width: 149px;
            width: 100%;
            height: 40px;
            margin: 11px 0px;
            font-size: 13px;
            letter-spacing: 0.1px;
            font-family: 'ActualR';
            box-shadow: none;
        }

    }
}

.financier_component {
    max-width: 1340px;
    margin: auto;
    width: 100%;
    background: transparent;
    // box-shadow: 0px 4px 15px rgba(102, 102, 102, 0.2);
    border-radius: 4px;

    .MuiTabs-scroller {
        margin-right: 0px;
        background: none;
        padding-top: 0px;
        padding-left: 0px;
    }

    .MuiBox-root {
        border: 0;
    }

    .MuiTab-root {
        padding: 16px 24px;
        font-family: 'ActualR';
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #666666;
        text-transform: capitalize;
    }

    .MuiTab-root.Mui-selected {
        color: #5029DD;
        background-color: #fff; 
    }

    .tabs_root{
        display: flex;
        background-color: #eee;
    .tab {
        text-transform: capitalize;
    }
    .MuiTabs-indicator {
        background-color: transparent;
        left: 214.4px;
        width: 177.338px;
        position: absolute;
        top: 0px;
        padding: 4px 0px 0px 0px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border-top: 4px solid #5029DD;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px; 
    }
  }
}

.consumptionMain {
    .Save_main_buttom{
        .button-var2_calculator {
            color: #fff;
            background: #48BC25;
            text-transform: unset;
            width: 180px;
            height: 48px;
            font-size: 13px;
            letter-spacing: .1px;
            font-family: "ActualR";
            box-shadow: none;
        }

        .MuiButton-root:hover {
            background-color: #48BC25;
            box-shadow: none;
            text-decoration: none;
        }
    }

    .main {
        // display: flex;
        gap: 24px;
.main_project_files{
    .header {
        max-width: 100%;
        // max-height:40px;
        .heading{
            margin-top: 8px;
font-family: "ActualM";
font-style: normal;
font-size: 18px;
color: #232323;
        }
        .header_top {
            display: flex;
            justify-content: space-between;

        }
        .button{
            display: flex;
            .selectPanel {
                border: 1px solid #AAAAAA;
                border-radius: 4px;
                width: 266px;
                height: 48px;

                // .MuiFilledInput-input {
                //     padding-top: 20px;
                // }

                #demo-simple-select-standard {
                    padding-top: 20px;
                }

                .MuiFormControl-root {
                    width: 100%;
                }

                .MuiFilledInput-root {
                    background-color: #FFFFFF;
                    width: 100%;
                    font-family: 'ActualR';
                    font-size: 14px;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;

                }

                .MuiFilledInput-root:before {
                    border-bottom: none;

                }

                .MuiFilledInput-root:after {
                    border-bottom: none;
                    background-color: #FFFFFF;

                }

                .MuiSelect-icon {
                    color: #5029DD;

                }

                .MuiInputBase-sizeSmall {
                    padding-bottom: 3px;
                }

                .MuiInputLabel-root {
                    font-family: 'ActualR';
                    font-weight: 400;
                    font-size: 14px;



                    color: #666666;
                }
            }
        }

        .MuiButtonBase-root.button-var2_file_1stbutton {
            color: white;
            background: #48BC25;
            ;
            text-transform: unset;
            // width: 100%;
            // max-width: 149px;
            width: 149px;
            height: 48px;
            font-size: 13px;
            letter-spacing: 0.1px;
            font-family: 'ActualR';
            cursor: pointer;
            box-shadow: none;
            display: flex;
            justify-content: center;
            margin-left: 16px;

        }

        .MuiButtonBase-root.button-var2_file_2ndbutton {
            color: #5029DD;
            background: #FFFFFF;
            border: 1px solid#5029DD;
            text-transform: unset;
            // width: 100%;
            // max-width: 149px;
            width: 149px;
            height: 40px;
            font-size: 13px;
            letter-spacing: 0.1px;
            font-family: 'ActualR';
            cursor: pointer;
            box-shadow: none;

        }
    }
}


        .utilityBill {
            align-items: center;
            padding: 24px 24px 0px;
            border: 1px solid rgba(102, 102, 102, 0.5);
            border-radius: 4px;
            height: auto;
            position: relative;
            max-width: 379px;
            width: 60%;
            max-height: 237px;

            .viewMore {
                font-family: 'ActualR';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.25px;
                color: #2266DD;
                cursor: pointer;
                position: absolute;
                right: 12px;
                bottom: 4px;
            }

            .utilityMain {
                display: flex;
                flex-direction: row;
                padding-bottom: 24px;
            }

            .imageUtilityDiv {
                display: flex;
                grid-gap: 10px;
                gap: 10px;
                max-width: 300px;
                flex-wrap: wrap;
                overflow: hidden;
                max-height: 90px;
                padding-bottom: 24px;

                .imageContainer {
                    position: relative;

                    .utilityImageStyles {
                        display: block;
                        border-radius: 4px;
                        margin: 10px 0px 5px 0px;
                        width: 145px;
                        max-width: 145px;
                        height: 85px;
                    }

                    .downloadIcon {
                        position: absolute;
                        right: 1%;
                        top: 12%;
                    }
                }


            }

            .button-var2_utilityBrowser {
                margin-left: 16px;
                background: #48BC25;
                border-radius: 4px;
                color: #fff;
                width: 81px;
                height: 34px;

            }

            .utilityText {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 16px;
                display: flex;
                align-items: center;
            }
        }

        // .panelMain {
        //     // border: 1px solid rgba(102, 102, 102, 0.5);
        //     // border-radius: 4px;
        //     // padding: 0px 24px;
        //     // position: relative;
        //     // max-height: 237px;
        //     // max-width: 629px;
        //     // width: 80%;

        //     .viewMore {
        //         font-family: 'ActualR';
        //         font-style: normal;
        //         font-weight: 500;
        //         font-size: 12px;
        //         line-height: 15px;
        //         letter-spacing: 0.25px;
        //         color: #2266DD;
        //         cursor: pointer;
        //         position: absolute;
        //         right: 12px;
        //         bottom: 4px;
        //     }

        //     .panelBox {
        //         display: flex;
        //         flex-direction: row;
        //         align-items: center;
        //         gap: 12px;
        //         height: 82px;

        //         .panelDiv {
        //             display: flex
        //         }

        //         .panelTextBtn {
        //             width: 197px;
        //         }

        //         .panelText {
        //             font-family: 'ActualR';
        //             font-weight: 400;
        //             font-size: 16px;
        //             align-items: center;
        //             display: flex;
        //         }

        //         .button-var2_panelBrowser {
        //             margin-left: 24px;
        //             width: 81px;
        //             height: 34px;
        //             background: #48BC25;
        //             border-radius: 6px;
        //             color: #fff
        //         }

        //         .verticalDivider {
        //             width: 0;
        //             border-left: 1px solid #aaa;
        //             height: 48px;
        //             margin: 0 16px;
        //         }

        //         .selectPanel {
        //             border: 1px solid #AAAAAA;
        //             border-radius: 4px;
        //             width: 266px;
        //             height: 48px;

        //             // .MuiFilledInput-input {
        //             //     padding-top: 20px;
        //             // }

        //             #demo-simple-select-standard {
        //                 padding-top: 20px;
        //             }

        //             .MuiFormControl-root {
        //                 width: 100%;
        //             }

        //             .MuiFilledInput-root {
        //                 background-color: #FFFFFF;
        //                 width: 100%;
        //                 font-family: 'ActualR';
        //                 font-size: 14px;
        //                 border-top-left-radius: 4px;
        //                 border-top-right-radius: 4px;
        //                 border-bottom-right-radius: 4px;
        //                 border-bottom-left-radius: 4px;

        //             }

        //             .MuiFilledInput-root:before {
        //                 border-bottom: none;

        //             }

        //             .MuiFilledInput-root:after {
        //                 border-bottom: none;
        //                 background-color: #FFFFFF;

        //             }

        //             .MuiSelect-icon {
        //                 color: #5029DD;

        //             }

        //             .MuiInputBase-sizeSmall {
        //                 padding-bottom: 3px;
        //             }

        //             .MuiInputLabel-root {
        //                 font-family: 'ActualR';
        //                 font-weight: 400;
        //                 font-size: 14px;



        //                 color: #666666;
        //             }
        //         }


        //     }

        //     .imagePanelDiv {
        //         display: flex;
        //         grid-gap: 10px;
        //         gap: 10px;
        //         max-width: 80%;
        //         flex-wrap: wrap;
        //         overflow: hidden;
        //         max-height: 90px;
        //         padding-bottom: 24px;

        //         .imageContainer {
        //             position: relative;

        //             .PanelImageStyles {
        //                 width: 145px;
        //                 max-width: 145px;
        //                 height: 85px;
        //                 border-radius: 4px;
        //                 margin: 10px 0px 5px 0px;
        //             }

        //             .downloadIcon {
        //                 position: absolute;
        //                 right: 1%;
        //                 top: 12%;
        //             }
        //         }
        //     }
        // }

        .horizontalDivider {
            width: 1072px;
            height: 1px;
            background: #C4C4C4;
        }
        .project-detail-files-component {

            // .MuiButtonBase-root-MuiButtonBase-root:hover {
            //     background-color:none;
            //     box-shadow: #fff !important;
            //     border: none !important;
            //     margin: none;
            //     padding: none;
            //     text-decoration: none;

            // }
            .MuiButton-root {
                background-color: #fff;
                box-shadow: none !important;

            }

            // .MuiDataGrid-root .MuiSwitch-switchBase .MuiSwitch-input:hover {
            //     background-color: none !important;
            //     }
            //     .MuiDataGrid-root.MuiSwitch-input:hover {
                main     //     background-color: none !important;
            //     }
            //     .MuiDataGrid-root .MuiSwitch-switchBase .MuiSwitch-input {
            //     background-color:  !important;
            //     }
            #demo-multiple-checkbox {
                width: 166px;
            }

            .MuiDataGrid-columnHeader {
                padding: 0px;
            }

            #select_partner {
                margin-left: -8px;

                .MuiOutlinedInput-root {
                    height: 34px;
                    width: 201px;
                }
            }

            .MuiIconButton-root {
                padding: 1px;
                margin-left: -8px;
            }

            .MuiDataGrid-columnHeaderTitleContainer {
                padding: 10px;
            }

            .css-nnbavb {
                float: left !important;
            }

            .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.left-align,
            .left-align {
                padding: 10px;
            }

            .projectList-width {
                .MuiDialog-container.MuiDialog-scrollPaper {

                    .MuiPaper-root-MuiDialog-paper,
                    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24 {
                        width: 100% !important;
                        height: 100% !important;
                    }
                }

                // .MuiButtonBase-root-MuiIconButton-root:hover{
                //     background:none !important;
                // }
                // .button:hover {
                //     background-color:transparent !important;
                // }




            }

            .MuiIconButton-root:hover {
                background-color: transparent !important;
                background: none;
                text-decoration: none;
            }

            .ForwardRef\(Switch\)-root-1 {

                .MuiSwitch-switchBase:hover {
                    background-color: transparent !important;
                    background: none;
                    text-decoration: none;
                }

            }


            .MuiDataGrid-columnSeparator {
                visibility: hidden;

            }

            .MuiBox-root .MuiCheckbox-root {
                color: rgb(110, 110, 110) !important;
            }

            .MuiCheckbox-root.MuiButtonBase-root.Mui-checked {
                color: var(--color-2) !important;
            }

            .no-result {
                height: auto;
                border: 1px solid #aaa;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0px;

                p {
                    font-family: actualM;
                    font-size: 16px;
                }
            }

            .header {
                max-width: 100%;
                // max-height:40px;

                .header_top {
                    display: flex;
                    justify-content: space-between;

                }

                .MuiButtonBase-root.button-var2_file_1stbutton {
                    color: white;
                    background: #48BC25;
                    ;
                    text-transform: unset;
                    // width: 100%;
                    // max-width: 149px;
                    width: 149px;
                    height: 40px;
                    font-size: 13px;
                    letter-spacing: 0.1px;
                    font-family: 'ActualR';
                    cursor: pointer;
                    box-shadow: none;
                    display: flex;
                    justify-content: center;

                }

                .MuiButtonBase-root.button-var2_file_2ndbutton {
                    color: #5029DD;
                    background: #FFFFFF;
                    border: 1px solid#5029DD;
                    text-transform: unset;
                    // width: 100%;
                    // max-width: 149px;
                    width: 149px;
                    height: 40px;
                    font-size: 13px;
                    letter-spacing: 0.1px;
                    font-family: 'ActualR';
                    cursor: pointer;
                    box-shadow: none;

                }
            }

            .MuiDataGrid-virtualScroller {
                overflow: auto;
            }

            .MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover {
                background-color: #fff;
            }

            .MuiDataGrid-root .MuiDataGrid-row:hover {
                background-color: #fff;
            }

            .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
                background-color: #fff;
            }

            .MuiDataGrid-columnHeaderTitle {
                font-family: 'ActualR';
                font-size: 12px;
                letter-spacing: 0.25px;
                color: #333333;
            }

            .heading {
                margin-top: 8px;
                font-family: 'ActualM';
                font-style: normal;

                font-size: 18px;


                color: #232323;

            }

            .button {
                display: flex;
            }

            .button-1st {
                padding-right: 16px;
            }

            .middle_part {
                // max-width: 768px;
                width: 100%;
                padding: 0px;

                .top-header {
                    height: 40px;
                    // min-width: 768px;
                    width: 100%;
                    background-color: var(--color-1);
                    border-radius: 4px 4px 0 0;
                    margin-top: 24px;
                }

                .MuiDataGrid-columnHeaders {
                    background-color: #F1F1F1;
                }

                @media only screen and (min-width: 1200px) {
                    .middle_part {
                        max-width: 1774px;

                    }
                }

                .MuiContainer-root {
                    padding: 0px;
                }

                #down_1 {
                    min-width: 135px;
                    width: 100%;
                    border: 1px solid #AAAAAA;
                    border-radius: 4px;
                    height: 32px;

                    #standard-drop {
                        padding-top: 0px;
                        padding-bottom: 0px;

                    }

                    #main_drop_place_text {
                        align-items: center;
                        font-family: 'ActualR';
                        font-style: normal;
                        font-size: 14px;
                        /* identical to box height */

                        letter-spacing: 0.25px;

                        color: #333333;
                        position: absolute;
                        padding-top: 5px;
                    }

                    .MuiFormControl-root {
                        width: 100%;
                    }

                    .MuiSelect-icon {
                        color: #5029DD;
                        top: calc(71% - 0.5em);
                    }

                    .MuiFilledInput-root {
                        background-color: #FFFFFF;
                        width: 100%;
                        font-family: 'ActualR';
                        font-size: 14px;
                        border-bottom: 1px solid #FFFFFF;

                    }


                    .MuiFilledInput-root:before {
                        border-bottom: 1px solid #FFFFFF;

                    }

                    .MuiFilledInput-root:after {
                        border-bottom: 1px solid #FFFFFF;
                        background-color: #FFFFFF;

                    }
                }

            }

            #select_partner {
                .MuiFormControl-root {
                    min-width: 104px;
                    width: 100%;
                    /* border: 1px solid #AAAAAA; */
                    border-radius: 4px;
                    /* height: 41px; */

                }

                #demo-multiple-checkbox {
                    padding: 3.5px 14px;
                }
            }

            .bill-pdf {
                font-family: 'ActualR';
                font-style: normal;

                font-size: 12px;


                letter-spacing: 0.25px;

                color: #2266DD;
                cursor: pointer;
                text-overflow: ellipsis;
                overflow: hidden;

            }

            .MuiButton-root {
                font-family: "ActualR";
                font-style: normal;
                font-size: 12px;
                letter-spacing: .25px;
                color: #26d;
                -webkit-justify-content: none;
                justify-content: left;
            }

            .date {
                font-family: 'ActualR';
                font-style: normal;

                font-size: 12px;


                letter-spacing: 0.25px;


                color: #666666;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .buttom_view {
                font-family: 'ActualR';
                font-style: normal;

                font-size: 12px;


                letter-spacing: 0.25px;

                color: #2266DD;
                cursor: pointer;
            }


        }
    }

    .secondMain {
        display: flex;
        width: 655px;
        height: 100px;
        gap: 24px;

        .utilitySts {
            padding: 0px 24px;
            width: 332px;
            height: 100px;
            left: 0px;
            top: 0px;
            border: 1px solid rgba(102, 102, 102, 0.5);
            border-radius: 4px;

            .utilityAPItxt {
                display: flex;
                justify-content: space-between;
                padding-top: 24px;
            }

            .panelText {
                padding: 5px 5px 5px 0
            }
            .Utility_api_buttom{
                .button-var2_utilityAPIBrowser {
                    color: #fff;
                    background: #48BC25;
                    text-transform: unset;
                    width: 75px;
                    height: 34px;
                    font-size: 13px;
                    letter-spacing: .1px;
                    font-family: "ActualR";
                    box-shadow: none;
                }
        
                .MuiButton-root:hover {
                    background-color: #48BC25;
                    box-shadow: none;
                    text-decoration: none;
                }
            }

            // .button-var2_utilityAPIBrowser {
            //     background: #48BC25;
            //     border-radius: 6px;
            //     width: 75px;
            //     height: 34px;
            // }

            .alertText {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 12px;
                color: #DD2200;
            }
        }

        .greenBtn {
            padding: 0px 24px;
            width: 332px;
            height: 100px;
            /* left: 0px; */
            /* top: 0px; */
            border: 1px solid rgba(102, 102, 102, 0.5);
            border-radius: 4px;
        }

        .greenBtntxt {
            display: flex;
            justify-content: space-between;
            padding-top: 24px;
        }

        .greenBtnText {
            padding: 9px 0px 33px 0px;
        }
        .buttom_greenBTn{
            .button-var2_greenBtnData {
                color: #fff;
                background: #48BC25;
                text-transform: unset;
                width: 83px;
                height: 34px;
                font-size: 13px;
                letter-spacing: .1px;
                font-family: "ActualR";
                box-shadow: none;
            }
    
            .MuiButton-root:hover {
                background-color: #48BC25;
                box-shadow: none;
                text-decoration: none;
            }
        }

        // .button-var2_greenBtnData {
        //     width: 83px;
        //     height: 34px;
        //     background: #48BC25;
        //     border-radius: 6px;
        // }
    }

    .refreshdropdown {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 24px 0px 4px 0px;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 24px;
        .Refresh{
            .button-var2_refreshBtn {
                color: #fff;
                background: #48BC25;
                text-transform: unset;
                width: 114px;
                height: 48px;
                font-size: 13px;
                letter-spacing: .1px;
                font-family: "ActualR";
                box-shadow: none;
            }
    
            .MuiButton-root:hover {
                background-color: #48BC25;
                box-shadow: none;
                text-decoration: none;
            }
        }

        .utilitySelect {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 266px;
            height: 48px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;



                color: #666666;
            }
        }

        .refreshdropdown2 {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;

            .utilitySelect {
                border: 1px solid #AAAAAA;
                border-radius: 4px;
                width: 266px;
                height: 48px;

                // .MuiFilledInput-input {
                //     padding-top: 20px;
                // }

                #demo-simple-select-standard {
                    padding-top: 20px;
                }

                .MuiFormControl-root {
                    width: 100%;
                }

                .MuiFilledInput-root {
                    background-color: #FFFFFF;
                    width: 100%;
                    font-family: 'ActualR';
                    font-size: 14px;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;

                }

                .MuiFilledInput-root:before {
                    border-bottom: none;

                }

                .MuiFilledInput-root:after {
                    border-bottom: none;
                    background-color: #FFFFFF;

                }

                .MuiSelect-icon {
                    color: #5029DD;

                }

                .MuiInputBase-sizeSmall {
                    padding-bottom: 3px;
                }

                .MuiInputLabel-root {
                    font-family: 'ActualR';
                    font-weight: 400;
                    font-size: 14px;



                    color: #666666;
                }
            }
        }
    }

    .account_pro {
        font-family: 'ActualR';
        font-size: 18px;
        margin-bottom: 27px;
    }

    .checkbox {
        display: flex;

        .check_p {
            margin-left: 2px;
            margin-top: 9px;
            margin-right: 6px;
            font-family: 'ActualR';
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            text-transform: capitalize;
        }

        .MuiCheckbox-root {
            padding: 9px 9px 9px 0px !important;
        }
    }
.Save_Account_properties{
    margin-top:27px;
    .button-var2_save {
        color: #fff;
        background: #48BC25;
        text-transform: unset;
        width: 99px;
        height: 46px;
        font-size: 13px;
        letter-spacing: .1px;
        font-family: "ActualR";
        box-shadow: none;
    }

    .MuiButton-root:hover {
        background-color: #48BC25;
        box-shadow: none;
        text-decoration: none;
    }
}
    // .button-var2_save {
    //     width: 99px;
    //     height: 46px;
    //     background: #48BC25;
    //     border-radius: 6px;

    //     .MuiButton-root:hover {
    //         background-color: #48bc25;
    //         box-shadow: none;
    //         text-decoration: none;
    //     }

    //     margin-top:27px;
    // }

    .costSchedules {
        .MuiDataGrid-columnHeaderTitle {
            font-weight: 600;
            background: #f1f1f1;
            font-family: 'ActualR';
            font-size: 12px;
            line-height: 15px;
            color: #333333;
        }

        .MuiDataGrid-root .MuiDataGrid-row {
            font-family: 'ActualR';
            font-weight: 400;
            font-size: 12px;

            color: #666666;
        }

        .MuiDataGrid-row.Mui-selected {
            background-color: #fff !important;
        }

        .MuiDataGrid-row.Mui-selected:hover {
            background-color: #fff !important;
        }

        .MuiDataGrid-row:hover {
            background-color: #fff !important;
        }

        .MuiDataGrid-iconSeparator {
            visibility: hidden;
        }
    }

    .rateSchedulesMain {
        .rateSchedules {
            // .MuiDataGrid-columnHeaders {
            //     background: #f1f1f1;
            //     font-family: 'ActualR';
            //     /* font-style: normal; */
            //     font-weight: 400;
            //     font-size: 12px;
            //     line-height: 15px;
            //     /* letter-spacing: 0.25px; */
            //     color: #333333;
            // }

            .MuiDataGrid-root .MuiDataGrid-row {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 12px;

                color: #666666;
            }

            .MuiDataGrid-columnHeaderTitle {
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                background: #f1f1f1;
                font-family: 'ActualR';
                color: #333333;
            }

            .MuiDataGrid-row.Mui-selected {
                background-color: #fff !important;
            }

            .MuiDataGrid-row.Mui-selected:hover {
                background-color: #fff !important;
            }

            .MuiDataGrid-row:hover {
                background-color: #fff !important;
            }

            .MuiDataGrid-iconSeparator {
                visibility: hidden;
            }

            .top-header {
                background-color: var(--color-1);
                color: white;
                border-radius: 4px 4px 0 0;
                margin-top: 20px;
                height: 40px;
            }
        }

        .rateSchedulesTitle {
            width: 129px;
            height: 23px;
            font-family: 'ActualR';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: #232323;
            margin-bottom: 20px;
        }
    }

    .feeNmonthlyCharge {
        display: flex;
        gap: 24px;
        padding: 24px 0px;

        .text_field {
            width: 230px;
            height: 48px;
            border: 1px solid #aaa;
            border-radius: 4px;

            .box_content {
                padding: 5px 16px;
            }

            #value {
                font-family: "ActualR";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                color: #727373;
            }

            #dollar {
                font-family: "ActualR";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #232323;
            }
        }
    }


    .calculationFields {
        display: flex;
        gap: 24px;
        padding-bottom: 24px;


        .intervalDropdown {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 180px;
            height: 48px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;
                color: #666666;
            }
        }

        .anualField {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 180px;
            // height: 48px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }
            .MuiFilledInput-input {
                padding-top: 20px !important;
            }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;
                color: #666666;
            }

        }
        .buttom_calculate{
            .button-var2_calculate {
                color: #fff;
                background: #48BC25;
                text-transform: unset;
                width: 126px;
                height: 48px;
                font-size: 13px;
                letter-spacing: .1px;
                font-family: "ActualR";
                box-shadow: none;
            }
    
            .MuiButton-root:hover {
                background-color: #48BC25;
                box-shadow: none;
                text-decoration: none;
            }
        }
        .Update_buttom{
            .button-var2_update {
                color: #5029DD;
                background: #FFF;
                text-transform: unset !important;
                width: 184px;
                height: 48px;
                font-size: 13px;
                letter-spacing: .1px;
                font-family: "ActualR";
                box-shadow: none;
                border: 1px solid #5029DD;
            }

            .MuiButton-root:hover {
                background-color: #fff;
                box-shadow: none;
                text-decoration: none;
            }
        }
        // .button-var2_update {
        //     width: 112px;
        //     height: 48px;
        //     color: #5029DD;
        //     border-radius: 4px;
        //     border: 1px solid #5029DD;
        //     ;
        //     background: none;
        // }

        .button-var2_calculator {
            width: 136px;
            height: 48px;
            background: #48BC25;
            border-radius: 6px;
        }
    }

    .MuiDataGrid-columnHeaders {
        background: #f1f1f1;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
        background-position: right;
        background-size: auto;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 6px;
        width: auto;
    }
    input[type="date"]::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-year-field {
        color:#212121 !important;
    }

    .tableTextfiled {
        align-items: center;
        padding: 4px 8px;
        width: 160px;
        border: 1px solid #AAAAAA;
        border-radius: 4px;
    }

    .kwhHeaderMain {
        padding: 0px;

        .kwhHeaderCell {
            width: 100px;
            height: 40px;
            background: #f1f1f1;
            border-radius: 4px;
        }
    }


    .icon-plus1:before {
        font-size: 12px;
        cursor: pointer;
        color: #666666;
    }

    .no-arrows {
        align-items: center;
        padding: 4px 8px;
        width: 160px;
        border: 1px solid;
        border-radius: 4px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        display: none;
    }

    /* Works for Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .no-arrows:hover {
        border-bottom: none;
    }

    .no-arrows:after {
        border-bottom: none;
    }

    .icon-plus1:before {
        font-size: 12px;
        cursor: pointer;
        color: #666666;
    }

    .top-header {
        background-color: var(--color-1);
        color: white;
        border-radius: 4px 4px 0 0;
        margin-top: 20px;
        height: 20px;

    }

    .RateCalculationTable {
        .MuiDataGrid-columnHeaderTitle {
            font-weight: 600;
            background: #f1f1f1;
            font-family: 'ActualR';
            font-size: 12px;
            line-height: 15px;
            color: #333333;
        }

        .MuiDataGrid-row.Mui-selected {
            background-color: #fff !important;
        }

        .MuiDataGrid-row.Mui-selected:hover {
            background-color: #fff !important;
        }

        .MuiDataGrid-row:hover {
            background-color: #fff !important;
        }

        .MuiDataGrid-iconSeparator {
            visibility: hidden;
        }

        //         .MuiDataGrid-columnHeaders {
        //             background: #f1f1f1;
        //             font-family: 'ActualR';
        // /* font-style: normal; */
        // font-weight: 400;
        // font-size: 12px;
        // line-height: 15px;
        // /* letter-spacing: 0.25px; */
        // color: #333333;
        //         }

        .MuiDataGrid-root .MuiDataGrid-row {
            font-family: 'ActualR';
            font-weight: 400;
            font-size: 12px;

            color: #666666;
        }
    }

    .tab_panel {
        .css-19kzrtu {
            padding: 24px 0px;
        }

    }
}

// .crate-design p {
//     font-family: 'ActualR';
//     font-style: normal;
//     font-weight: 400;
//     font-size: 18px;
//     color: #333333;
//     margin-top: 16px;
//     margin-bottom: 16px;
// }

.crate-design input {
    font-size: 14px;
    padding: 0px 10px;
}




.system-design {


    .heading {
        font-family: 'ActualM';

        font-weight: 500;
        font-size: 18px;

        color: #232323;
    }

    .middle-part {
        display: flex;
        margin-top: 16px;
        justify-content: space-between;

        .left-side {
            display: flex;
            justify-content: flex-start;

            .left-dropdown {
                border: 1px solid #AAAAAA;
                border-radius: 4px;
                width: 205px;
                height: 48px;

                // .MuiFilledInput-input {
                //     padding-top: 20px;
                // }

                #demo-simple-select-standard {
                    padding-top: 20px;
                }

                .MuiFormControl-root {
                    width: 100%;
                }

                .MuiFilledInput-root {
                    background-color: #FFFFFF;
                    width: 100%;
                    font-family: 'ActualR';
                    font-size: 14px;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;

                }

                .MuiFilledInput-root:before {
                    border-bottom: none;

                }

                .MuiFilledInput-root:after {
                    border-bottom: none;
                    background-color: #FFFFFF;

                }

                .MuiSelect-icon {
                    color: #5029DD;

                }

                .MuiInputBase-sizeSmall {
                    padding-bottom: 3px;
                }

                .MuiInputLabel-root {
                    font-family: 'ActualR';
                    font-weight: 400;
                    font-size: 14px;



                    color: #666666;
                }
            }

            #hr_tag {
                width: 0;
                border-left: 1px solid #aaa;
                height: 48px;
                margin: 0 16px;
            }

            .button-var2_header-left {
                color: #fff;
                background: #48bc25;
                text-transform: unset;
                width: 162px;
                height: 48px;
                font-size: 13px;
                letter-spacing: .1px;
                font-family: "ActualM";
                box-shadow: none;
            }

            .MuiButton-root:hover {
                background-color: #48bc25;
                box-shadow: none;
                text-decoration: none;
            }

        }

        .right-side {
            display: flex;
            justify-content: flex-start;

            .box_2 {
                width: 268px;
                height: 48px;
                border: 1px solid #aaa;
                border-radius: 4px;
                margin-left: 16px;
                background-color: #F1F1F1;

                .box_content {
                    padding: 5px 16px;
                }

                #value {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    color: #727373;
                }

                #dollar {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #232323;
                }

            }

            .right-button_1 {
                margin-left: 16px;

                .button-var2_header-right {
                    color: #fff;
                    background: #48bc25;
                    text-transform: unset !important;
                    width: 132px;
                    height: 48px;
                    font-size: 13px;
                    letter-spacing: .1px;
                    font-family: "ActualM";
                    box-shadow: none;


                    .MuiButton-root:hover {
                        background-color: #48bc25;
                        box-shadow: none;
                        text-decoration: none;
                    }
                }
            }

            .right-button_2 {
                margin-left: 16px;

                .button-var2_header-right_1 {
                    color: #fff;
                    background: #48bc25;
                    text-transform: unset !important;
                    width: 185px;
                    height: 48px;
                    font-size: 13px;
                    letter-spacing: .1px;
                    font-family: "ActualM";
                    box-shadow: none;


                    .MuiButton-root:hover {
                        background-color: #48bc25;
                        box-shadow: none;
                        text-decoration: none;
                    }
                }
            }

        }

    }

    .notes_field {

        .tagline {
            position: absolute;
            padding-left: 16px;
            padding-top: 20px;
            font-family: 'ActualR';
            font-weight: 400;
            font-size: 12px;
            color: #727373;

        }

        .text {
            width: 96.6%;
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            margin-top: 16px;
            padding: 17px 21px 0 16px;
            font-family: 'ActualR';
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            background-color: #F1F1F1;
        }
    }

    .notes_hr {
        margin-top: 14px;
    }

    .buttom-part {
        width: 100%;
        box-sizing: border-box;
        padding-right: 24px;

        border: 1px solid rgba(102, 102, 102, 0.5);
        border-radius: 4px;

    }

}

.Roofplane {
    .header_part {
        display: flex;
        justify-content: flex-start;

        .button_1 {

            .button-var2_header-left {
                color: #fff;
                background: #48bc25;
                text-transform: unset;
                width: 162px;
                height: 48px;
                font-size: 13px;
                letter-spacing: .1px;
                font-family: "ActualM";
                box-shadow: none;
            }

            .MuiButton-root:hover {
                background-color: #48bc25;
                box-shadow: none;
                text-decoration: none;
            }
        }

        .button_2 {
            margin-left: 16px;

            .button-var2_System-Data {
                color: #2266DD;
                background: #FFFFFF;
                text-transform: unset;
                width: 255px;
                height: 48px;
                font-size: 13px;
                letter-spacing: .1px;
                font-family: "ActualM";
                box-shadow: none;
                border: 1px solid #2266DD;
                border-radius: 6px;
            }

            .button-var2_Rerun {
                color: #2266DD;
                background: #FFFFFF;
                text-transform: unset;
                width: 102px;
                height: 48px;
                font-size: 13px;
                letter-spacing: .1px;
                font-family: "ActualM";
                box-shadow: none;
                border: 1px solid #2266DD;
                border-radius: 6px;
            }

            .button-var2_Edit_Manually {
                color: #2266DD;
                background: #FFFFFF;
                text-transform: unset;
                width: 151px;
                height: 48px;
                font-size: 13px;
                letter-spacing: .1px;
                font-family: "ActualM";
                box-shadow: none;
                border: 1px solid #2266DD;
                border-radius: 6px;
            }
        }
    }

    .dropdwon_part {
        display: flex;
        justify-content: flex-start;
        margin-top: 24px;

        .panel-dropdown {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 331px;
            height: 48px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            // .MuiInputBase-sizeSmall {
            //     padding-bottom: 3px;
            // }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;



                color: #666666;
            }

        }

        .inverter_dropdown {
            margin-left: 16px;
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 331px;
            height: 48px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            // .MuiInputBase-sizeSmall {
            //     padding-bottom: 3px;
            // }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;



                color: #666666;
            }



        }

        // .production_dropdown {
        //     margin-left: 16px;
        //     border: 1px solid #AAAAAA;
        //     border-radius: 4px;
        //     width: 331px;
        //     height: 48px;

        //     // .MuiFilledInput-input {
        //     //     padding-top: 20px;
        //     // }

        //     #demo-simple-select-standard {
        //         padding-top: 20px;
        //     }

        //     .MuiFormControl-root {
        //         width: 100%;
        //     }

        //     .MuiFilledInput-root {
        //         background-color: #FFFFFF;
        //         width: 100%;
        //         font-family: 'ActualR';
        //         font-size: 14px;
        //         border-top-left-radius: 4px;
        //         border-top-right-radius: 4px;
        //         border-bottom-right-radius: 4px;
        //         border-bottom-left-radius: 4px;

        //     }

        //     .MuiFilledInput-root:before {
        //         border-bottom: none;

        //     }

        //     .MuiFilledInput-root:after {
        //         border-bottom: none;
        //         background-color: #FFFFFF;

        //     }

        //     .MuiSelect-icon {
        //         color: #5029DD;

        //     }

        //     // .MuiInputBase-sizeSmall {
        //     //     padding-bottom: 3px;
        //     // }

        //     .MuiInputLabel-root {
        //         font-family: 'ActualR';
        //         font-weight: 400;
        //         font-size: 14px;



        //         color: #666666;
        //     }


        // }
        .production_dropdown {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 331px;
            margin-left: 16px;
            // height: 48px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }
            .MuiFilledInput-input {
                padding-top: 20px !important;
            }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;
                color: #666666;
            }

        }

    }

    .middle_part {
        margin-top: 16px;
        display: flex;
        justify-content: flex-start;

        //     .box_3{

        //         width: 222px;
        //         height: 48px;
        //         border: 1px solid #aaa;
        //         border-radius: 4px;


        //         .box_content {
        //             padding: 5px 16px;
        //         }

        //         #value {
        //             font-family: "ActualR";
        //             font-style: normal;
        //             font-weight: 400;
        //             font-size: 12px;
        //             color: #727373;
        //         }

        //         #dollar {
        //             font-family: "ActualR";
        //             font-style: normal;
        //             font-weight: 400;
        //             font-size: 14px;
        //             color: #232323;
        //         }


        // }
        .anualField_1 {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 180px;
            // height: 48px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }
            .MuiFilledInput-input {
                padding-top: 20px !important;
            }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;
                color: #666666;
            }

        }

        .box_2 {
            width: 222px;
            height: 48px;
            border: 1px solid #aaa;
            border-radius: 4px;
            margin-left: 16px;
            background-color: #F1F1F1;


            .box_content {
                padding: 5px 16px;
            }

            #value {
                font-family: "ActualR";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                color: #727373;
            }

            #dollar {
                font-family: "ActualR";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #232323;
            }


        }

        .anualField_2 {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 207px;
            margin-left: 16px;
            // height: 48px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }
            .MuiFilledInput-input {
                padding-top: 20px !important;
            }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;
                color: #666666;
            }

        }


        .roof_metarial_dropdown {
            margin-left: 16px;
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 181px;
            height: 48px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            // .MuiInputBase-sizeSmall {
            //     padding-bottom: 3px;
            // }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;



                color: #666666;
            }


        }

        .check_p {
            margin-top: 9px;
            /* margin-right: 6px; */
            font-family: 'ActualR';
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            width: 117px;
        }

        .checked {

            //        .MuiBox-root.MuiCheckbox-root{
            //     color:rgb(110,110,110) !important;
            //  }
            //  .MuiCheckbox-root.MuiButtonBase-root.Mui-checked {
            //     color: var(--color-2) !important;
            // }
            .MuiCheckbox-root {
                color: rgb(110, 110, 110) !important;

            }

            .MuiCheckbox-root.MuiButtonBase-root.Mui-checked {
                color: var(--color-2) !important;
            }
        }


        .box_1 {
            width: 181px;
            height: 48px;
            border: 1px solid #aaa;
            border-radius: 4px;
            margin-left: 16px;


            .box_content {
                padding: 5px 16px;
            }

            #value {
                font-family: "ActualR";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                color: #727373;
            }

            #dollar {
                font-family: "ActualR";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #232323;
            }
        }

        // .checked{

        // }

    }

    .project-detail-files-component {

        .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.left-align,
        .left-align {
            padding: 0 10px;
        }

        .MuiDataGrid-row.Mui-selected {
            background-color: #fff !important;
        }

        .MuiDataGrid-row.Mui-selected:hover {
            background-color: #fff !important;
        }

        .MuiDataGrid-row:hover {
            background-color: #fff !important;
        }

        .MuiDataGrid-iconSeparator {
            visibility: hidden;
        }

        .MuiContainer-root {
            padding-left: 0px;
            padding-right: 0px;
        }

        .MuiDataGrid-columnHeaders {
            background-color: #F1F1F1;
            font-family: 'ActualR';
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.25px;
            color: #333333;
            height: 20px;

        }

        // .MuiDataGrid-columnHeaderTitle{
        //     overflow: none;
        //     white-space: normal;
        //     word-wrap: break-word;
        //     margin-left: auto;
        //     margin-right: auto;
        //     margin-top: auto;
        //     margin-bottom: auto;f

        // } 
        .bill-pdf {
            align-items: center;
            padding: 4px 8px;

            width: 120px;
            height: 32px;


            border: 1px solid #AAAAAA;
            border-radius: 4px;

        }

        .aroraTypeSelect {
            width: 90px;
            height: 32px;

            .MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
                background-color: #fff !important;
            }

            .MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover {
                background-color: #fff !important;
            }
        }

        .roofTypeSelect {
            width: 110px;
            height: 32px;

            .MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
                background-color: #fff !important;
            }

            .MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover {
                background-color: #fff !important;
            }
        }

        .Aurora-Design_1 {

            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 110px;
            height: 32px;


            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
                padding-top: 0px;
                padding-left: 0px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 11px;
                line-height: 20px;
                color: #232323;
                position: absolute;
                left: -4px;
                top: -8px;
            }

            .MuiAutocomplete-endAdornment {
                right: 0px;
                top: 2px;
            }





        }

        .Aurora-Design {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 90px;
            height: 32px;


            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
                padding-top: 0px;
                padding-left: 0px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 11px;
                line-height: 20px;
                color: #232323;
                position: absolute;
                left: -4px;
                top: -8px;
            }

            .MuiAutocomplete-endAdornment {
                right: 0px;
                top: 2px;
            }



        }

        .panel-count {
            align-items: center;
            padding: 4px 8px;
            width: 54px;
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            font-family: 'ActualR';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #232323;
        }

        .Tilt {
            width: 50px;
            align-items: center;
            padding: 4px 8px;

            border: 1px solid #AAAAAA;
            border-radius: 4px;
            font-family: 'ActualR';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #232323;
        }

        .Azimuth {
            width: 41px;
            align-items: center;
            padding: 4px 8px;

            border: 1px solid #AAAAAA;
            border-radius: 4px;
            font-family: 'ActualR';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #232323;
        }

        .errorHandling {
            border: 1px solid red;
            width: 39px;
            align-items: center;
            padding: 4px 8px;
            border-radius: 4px;
            font-family: 'ActualR';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }

        .TSRF {
            width: 39px;
            align-items: center;
            padding: 4px 8px;
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            font-family: 'ActualR';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #232323;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .YearlyProduction {
            width: 90px;
            align-items: center;
            padding: 4px 8px;

            border: 1px solid #AAAAAA;
            border-radius: 4px;
            font-family: 'ActualR';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #232323;

        }

        .footerBtn {
            display: flex;
            justify-content: space-between;
            padding: 12px;
        }

        .button-var2_header-grid {
            color: #fff;
            background: #48bc25;
            text-transform: unset;
            min-width: 90px;
            height: 32px;
            font-size: 13px;
            // letter-spacing: .1px;
            font-family: "ActualR";
            box-shadow: none;
        }

        .button-var2_save_panels {
            color: #fff;
            background: #48bc25;
            text-transform: unset;
            // width: 142px;
            height: 32px;
            font-size: 13px;
            letter-spacing: .1px;
            // font-family: "ActualR";
            box-shadow: none;
        }

        .top-header {
            padding: 12px 16px;
            background-color: var(--color-1);
            color: white;
            border-radius: 4px 4px 0 0;
            margin-top: 20px;
            height: 40px;
        }

        .MuiDataGrid-virtualScroller {
            overflow: hidden;
        }
    }

    .Footer_part {

        margin-top: 16px;
        height: auto;

        /* Fields Boarders Outline Borders (50%) Opacity */

        border: 1px solid rgba(102, 102, 102, 0.5);
        border-radius: 4px;

        .heding_footer {
            font-family: 'ActualM';
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #232323;
            padding: 24px;
        }

        .footer_middle_part {
            padding: 16px;
            height: auto;
            background: #F1F1F1;
            border-radius: 4px;
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 24px;
            display: flex;
            justify-content: flex-start;

            .twodmain {
                width: 205px;
                height: auto;
                background: #FFFFFF;
                border-radius: 6px;
                padding: 16px;

                .box_1 {
                    display: flex;
                    align-items: center;
                    grid-gap: 18px;
                    gap: 36px;

                }

                .imageContainer {
                    position: relative;
                    margin-top: 16px;

                    .utilityImageStyles {
                        width: 100%;
                        height: 140px;
                    }

                    .downloadNremove {
                        .downloadIcon {
                            position: absolute;
                            right: 16%;
                            top: 3%;
                        }

                        .removeIcon {
                            position: absolute;
                            right: 5%;
                            top: 0%;
                            color: #DD2200;
                            cursor: pointer;
                        }
                    }

                }
            }



            .text_type {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 15px;
                color: #232323;
            }

            .button-var2_1st {
                color: #fff;
                background: #48bc25;
                text-transform: unset;
                width: 113px;
                height: 48px;
                font-size: 13px;
                letter-spacing: .1px;
                font-family: "ActualM";
                box-shadow: none;
                border-radius: 6px;
                padding: 15px 32px;

                .MuiButton-root:hover {
                    background-color: #48bc25;
                    box-shadow: none;
                    text-decoration: none;
                }
            }

            .threedmain {
                width: 205px;
                height: auto;
                background: #FFFFFF;
                border-radius: 6px;
                padding: 16px;
                margin-left: 16px;

                .box_2 {
                    display: flex;
                    align-items: center;
                    grid-gap: 18px;
                    gap: 36px;
                    border-radius: 6px;
                }

                .imageContainer {
                    position: relative;
                    margin-top: 16px;

                    .utilityImageStyles {
                        width: 100%;
                        height: 140px;
                    }

                    .downloadNremove {
                        .downloadIcon {
                            position: absolute;
                            right: 16%;
                            top: 3%;
                        }

                        .removeIcon {
                            position: absolute;
                            right: 5%;
                            top: 0%;
                            color: #DD2200;
                            cursor: pointer;
                        }
                    }

                }
            }

            .irradianceMain {
                height: auto;
                background: #FFFFFF;
                border-radius: 6px;
                padding: 16px;
                margin-left: 16px;
                width: 240px;

                .box_3 {
                    display: flex;
                    align-items: center;
                    grid-gap: 18px;
                    gap: 16px;
                    border-radius: 6px;
                }

                .imageContainer {
                    position: relative;
                    margin-top: 16px;

                    .utilityImageStyles {
                        width: 100%;
                        height: 140px;
                    }

                    .downloadNremove {
                        .downloadIcon {
                            position: absolute;
                            right: 16%;
                            top: 3%;
                        }

                        .removeIcon {
                            position: absolute;
                            right: 5%;
                            top: 0%;
                            color: #DD2200;
                            cursor: pointer;
                        }
                    }

                }
            }

            .dxfMain {
                height: auto;
                background: #FFFFFF;
                border-radius: 6px;
                padding: 16px;
                margin-left: 16px;
                width: 220px;

                .box_4 {
                    display: flex;
                    align-items: center;
                    grid-gap: 18px;
                    gap: 16px;
                    border-radius: 6px;
                }

                .imageContainer {
                    position: relative;
                    margin-top: 16px;

                    .utilityImageStyles {
                        width: 100%;
                        height: 140px;
                    }

                    .downloadNremove {
                        .downloadIcon {
                            position: absolute;
                            right: 16%;
                            top: 3%;
                        }

                        .removeIcon {
                            position: absolute;
                            right: 5%;
                            top: 0%;
                            color: #DD2200;
                            cursor: pointer;
                        }
                    }

                }
            }

        }
    }
}

.buttom_footer {
    display: flex;
    .button-var2_save {
        color: #fff;
        background: #48bc25;
        text-transform: unset;
        width: 224px;
        height: 48px;
        font-size: 13px;
        letter-spacing: .1px;
        font-family: "ActualM";
        box-shadow: none;
        margin-top: 16px;

        .MuiButton-root:hover {
            background-color: #48bc25;
            box-shadow: none;
            text-decoration: none;
        }
    }
}

.button-var2_refreshBtn:hover,
.button-var2_save:hover,
.button-var2_greenBtnData:hover,
.button-var2_utilityAPIBrowser:hover,
.button-var2_calculator:hover,
.button-var2_calculate:hover {
    color: white;
    background: #48BC25 !important;
}

.no-result {
    height: 250px;
    width: 100%;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
        font-family: actualM;
        font-size: 16px;
    }
}

.add_design {
    .MuiDialogContent-root {
        border-bottom: none !important;
    }

    .MuiDialogActions-root {
        display: flex !important;
        justify-content: flex-start !important;
    }

    .MuiDialog-paper {
        width: 679px !important;
        height: 239px !important;
    }

    .MuiButtonBase-root.button-var2,
    .button-var2 {
        color: #fff;
        background: #48bc25;
        text-transform: unset;
        width: 160px;
        height: 35px;
        font-size: 13px;
        letter-spacing: .1px;
        font-family: "ActualR";
        box-shadow: none;
        margin-right: 24px;
        margin-left: 16px;

        .MuiButton-root:hover {
            background-color: #48bc25;
            box-shadow: none;
            text-decoration: none;
        }
    }

    .MuiButtonBase-root.button-var3,
    .button-var3 {
        color: #2266DD;
        background: #fff;
        text-transform: unset;
        width: 160px;
        height: 35px;
        font-size: 13px;
        letter-spacing: .1px;
        font-family: "ActualR";
        box-shadow: none;
        border: 2px solid #2266DD;


        .MuiButton-root:hover {
            background-color: #fff;
            box-shadow: none;
            text-decoration: none;
        }
    }


}

.add-design {
    background-color: #6cbd29;
    padding: 4px 10px;
    border-radius: 26%;
}

.plus-panel {
    font-size: 16px;
    color: white;
    font-weight: bolder;
}