@import url("https://assets.solar.com/partner_assets/actual-fonts/actual-fonts.css");
@import url("https://assets.solar.com/partner_assets/control-center/font/style.css");
@import url("https://cdn.icomoon.io/305419/Hubble/style.css?qdeqvy");

*::after,
*::before {
    box-sizing: inherit;
}
* {
    box-sizing: border-box;
}
body {
    background-color: #eee !important;
    font-size: 14px;
    overflow: auto;
    height: 100%;
    margin: 0;
    font-family: ActualR, Roboto;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin: 0;
        padding: 0;
        letter-spacing: 0;
        font-family: ActualR, Roboto;
        text-align:left;
    }

    h1.control-bold,
    h2.control-bold,
    h3.control-bold,
    h4.control-bold,
    h5.control-bold,
    h6.control-bold,
    p.control-bold {
        font-family: ActualM, Roboto;
    }

    h1 {
        font-size: 32px;
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 20px;
    }

    h5 {
        font-size: 18px;
    }

    h6 {
        font-size: 16px;
    }

    .control-sm {
        font-size: 12px;
    }

    .control-md {
        font-size: 14px;
    }

    .control-lg {
        font-size: 16px;
    }

    button {
        font-family: ActualM, Roboto;
        font-size: 14px;
        text-transform: capitalize;
        letter-spacing: 1.25px;
    }

    .hint {
        font-family: ActualR, Roboto;
        font-size: 10px;
        letter-spacing: 1.5px;
    }

    a {
        font-family: ActualR, Roboto;
        // font-size: 10px;
        text-decoration: none;
        color: #2266DD;
        // letter-spacing: 1.5px;
    }
    p{
        margin-bottom: 0;
    }
}
.main-head {
    margin-top: 62px !important;
}
.react-datepicker-popper{
    z-index:9 !important;
  }

.btn-layout:hover {
    color:white !important;
}
.btn{
    box-shadow: none;;
}

.green-1{
    background :#48bc25;
    color:white;
}
.MuiFormGroup-root, .MuiBox-root, .MuiFormControlLabel-root{
    font-family: ActualR;
    .MuiRadio-root.Mui-checked, .MuiCheckbox-root.Mui-checked, 
    .MuiCheckbox-root.Mui-checked, .MuiCheckbox-root.MuiCheckbox-indeterminate,
    .MuiCheckbox-root{
        color: var(--color-2) !important;
    }
}
span.MuiButtonBase-root-MuiRadio-root.Mui-checked, span.MuiButtonBase-root-MuiRadio-root{
    color: var(--color-2) !important;
}
.react-datepicker__day--selected, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
    background-color: var(--color-2) !important;
}

.checkboxTree-component.tree-view{
 .rct-checkbox, .rct-node-icon{
     display:none !important;
 }
}

.outer-box{
    box-shadow: 0 4px 15px 0 rgba(102, 102, 102, 0.2);
    background-color: #fff;
    border-radius: 4px;
      .top-header{
      padding: 12px 16px;
      background-color: #333333;
      color:white;
      border-radius: 4px 4px 0 0;
      text-align:left;
   }
}
// .filter-section{
//     align-items: end;
//     flex-wrap:wrap;
//     margin-bottom:15px;
//     font-family: ActualR;
//     font-size: 14px;
//     .MuiFormControl-root{
//         margin:10px 15px 0 0;
//     }
//     .MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiOutlinedInput-input.MuiInputBase-input{
//         padding:7px;
//         font-family: ActualR;
//         font-size: 14px;
//     }
//     .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input{
//         padding: 6px;
//         min-width:50px;
//         font-family: ActualR;
//         font-size: 14px;
//     }
//     .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
//         legend{
//             display:none;
//         }
//     }
//     .MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
//     .MuiCheckbox-root.MuiButtonBase-root.Mui-checked{
//         color:var(--color-2);
//     }
// }
.MuiButtonBase-root-MuiMenuItem-root .MuiListItemText-root{
    .MuiTypography-root{
    font-family: ActualR;
    font-size: 14px;
    }
}
.MuiList-root.MuiList-padding.MuiMenu-list{
    .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root{
        padding: 0;
        font-family: ActualR;
        font-size: 14px;
    }
}
.MuiButtonBase-root-MuiMenuItem-root .MuiListItemText-root{
.MuiTypography-root{
font-family: ActualR;
font-size: 14px;
}
}
.MuiList-root.MuiList-padding.MuiMenu-list{
.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root{
    padding: 0;
    font-family: ActualR;
    font-size: 14px;
}
}

.MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
.MuiCheckbox-root.MuiButtonBase-root.Mui-checked{
color:var(--color-2);
}


.MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell, .MuiDataGrid-columnHeaderTitle  {
    white-space: normal !important;
    word-break: break-word;
    text-align:left;
    // text-transform: capitalize;
    font-size:12px;
    font-family: 'ActualR';
    line-height: normal;
}

.MuiDataGrid-virtualScroller {
    overflow: auto !important;
    height: auto !important;
    margin-top: 48px !important;
  }

.icon_tooltip::before{
    font-size: x-large;
    color: #666666 !important;
    opacity: 50%;
}

.icon_downarrow::before{
    font-size: x-large;
    color: #5029DD !important;
    padding: 0px 16px 0px 0px;
}

.icon_downarrow_autocomplete_field::before{
    font-size: x-large;
    color: #5029DD !important;
}

//Home
.home-dashboard{
    width:100%;
    background: #eee;
    height: 85vh;
.dashboard-landing{
        background: var(--color-1);
        height:198px;
        position: absolute;
        width: 100%;
    }
    .cont{
        padding: 36px 40px 0;
    }
     .cards{
        //  height:107px;
         width: 205px;
         border-radius: 16px;
         background:white;
         .card-body{
            img{
                width:40px;
                height:40px;
                margin:auto;
            }
            p.text{
                font-family:ActualM;
                font-style: normal;
                font-size: 14px;
                color: var(--color-4);
                margin-bottom:0;
                margin-top:5px;
                text-transform: capitalize;
            }

         }
     }
     .welcome-user{
        font-family: ActualR;
        font-style: normal;
        color: #FFFFFF;
        text-transform: capitalize;;
        max-width:1240px;
        margin:auto;
        margin-top:48px;
        width:100vw;
        padding:0 20px;
        .greeting{
            font-size: 28px;
        }
        .text{
            font-size: 12px;
        }
        .time-format{
            margin-top:10px;
            div{
                margin:0 10px;
                i{
                    margin-right:5px;
                }
            }
        }
     }
}
.card {
    cursor: pointer;
    box-shadow: 2px 2px 2px grey;

}

.card:hover {
    box-shadow: 4px 4px 4px grey;
}

.mainLayout-component{
    width:auto;
    min-height:88vh;
}

@media only screen and (max-width: 480px){
    .home-dashboard{
        height: auto;
         .cards{
             height:auto;
             width: 20rem;
             .card-body{
                 display:flex;
                 justify-content: flex-start;
                 align-items:center;
                 img{
                    height:60px;
                    margin: 0 30px 0 0;
                }

             }
         }
    }
}


//
.filter-section{
    align-items: end;
    flex-wrap:wrap;
    margin-bottom:15px;
    font-family: ActualR;
    font-size: 14px;
    .MuiFormControl-root{
        margin:10px 15px 0 0;
    }
    .MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiOutlinedInput-input.MuiInputBase-input{
        padding:7px;
        font-family: ActualR;
        font-size: 14px;
        text-align:left;
    }
    .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input{
        padding: 6px;
        min-width:50px;
        font-family: ActualR;
        font-size: 14px;
    }
    .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        legend{
            display:none;
        }
    }
    .MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate,
    .MuiCheckbox-root.MuiButtonBase-root.Mui-checked{
        color:var(--color-2);
    }
}
.hubble-checkout{
.MuiSwitch-track{
   min-height:20px;
   margin-top: -3px;
   border-radius: 10px !important;
}
}
*:disabled , .disabled{
    cursor : not-allowed;
}


.custom-pagination-item .MuiButtonBase-root{
    border-radius: 4px;
}
.pagination-text-style{
    font-family: 'ActualR';
    color: #565758;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.5px;
    letter-spacing: 0.25px;
    text-align: right;
}


.disabled-row{
    .MuiDataGrid-cell {
        background: #efeaea !important;
    }
   }
