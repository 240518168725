.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 2;
  top: 40px;
  left: 56px;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: 0px 4px 15px rgba(102, 102, 102, 0.2);
}

.sidenav a {
  color: black;
  display: block;
  transition: 0.3s;
}


.sidenav a:hover {
  color: black;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

#main {
  transition: margin-left .5s;
  padding: 24px 40px;

  p.active-route-title {
    font-family: ActualR;
    font-style: normal;
    font-size: 24px;
    color: #212121;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

.nav-s {
  display: block;
  padding: 20px;
  text-align: left;

  .nav-link {
    padding: 0;
    font-size: 12px;
    opacity: 0.9;
    margin: 5px 0;
    color: #212121;
    cursor: pointer;
  }

  .nav-link:hover {
    opacity: 1;
    color: black;
  }

  .nav-link.active {
    opacity: 1;
    color: #5029DD;
    font-family: 'ActualB';
    font-size: 12px;
  }
}

.open {
  width: 0
}

.open-mleft {
  margin-left: 56px;
  margin-bottom: 65px;
}

.close {
  width: 200px
}

.close-mleft {
  margin-left: 56px;
  margin-bottom: 65px;
}

.closeBtn {
  font-size: 26px;
  cursor: pointer;
  margin: 0 16px 0 0
}

.font-close:before {
  text-align: end;
  color: white;
  display: none;
}
.fixed-sidebar-icon{
  // height: 100vh;
  width: 56px;
  left: 0;
  word-break: break-all;
  padding: 30px 0;
  height: 100%;
  position: fixed;
  z-index: 2;
  top: 40px;
  left: 0;
  background-color: #eee;
  overflow-y: hidden;
  box-shadow: 0px 4px 15px rgba(102, 102, 102, 0.2);
  .menu-content{
    padding: 10px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .menu-content.active{
    border-left: 3px solid var(--color-2);
    background:white;
  }
  .menu-content.nocolor{
    border-left: 3px solid var(--color-5);
    background:white;
  }
  
  .icon{
    width:30px;
  }
  .MuiTooltip-tooltip{
    font-family:ActualR;
    font-family:16px;
    margin-left:12px;
  }
  .side-icon{
    font-size:30px
  }
  .side-icon.active::before{
   color: var(--color-2);
  }
}
.divider{
  color:white;
}
@media only screen and (max-width:480px) {
  .font-close:before {
    display: block;
  }

  .close {
    width: 100%;
    max-width: 260px;
  }

  .close-mleft {
    margin-left: 0;
  }

}

#sidebar-component{
  background:white;
  .fixed-sidebar-menu{
    width: 56px;
    height: 100vh;
    background: #eeeeee;
    position: fixed;
    padding: 20px 10px;
  }

  .MuiPaper-root.MuiAccordion-root{
    background: transparent;
    color: white;
    font-size: 12px;
    font-family: 'ActualR';
    padding: 0;
    .MuiButtonBase-root.MuiAccordionSummary-root{
      min-height: 0;
      padding: 0;
    }
    .MuiAccordionSummary-content{
      margin:0;
    }
    .MuiAccordionSummary-expandIconWrapper{
      color: white;
      transform: rotate(270deg);
    }
    .MuiAccordionSummary-expandIconWrapper.Mui-expanded{
     transform: rotate(360deg);
    }
  }
}