.outer-cont{
    width:100%;
    height:100vh;
    background-image: url('https://assets.solar.com/partner_assets/control-center/Hubble-bg.jpg');
    background-size: cover;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    .login-container{
        color: white;
        padding:40px 60px;
        border-radius: 6px;
        /* box-shadow: black 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px; */
        text-align: center;
        flex-direction: column;
        align-items: center;
        font-family:ActualR;
        background: white; //white
        border-radius:4px;
        margin: auto;
        img{
            width:120px;
        }
        h2 {
            margin-bottom: 15px;
            font-size:24px;
            font-family:ActualM;
        }
        div.cont-log{
            background: white;  // white
            color: black;
            padding: 20px 20px 0 20px;
            border-radius: 6px;
            button{
                font-family:ActualM !important;
                max-width: 250px;
                width:100%;
                justify-content: center;
                border-radius: 4px !important;
                background-color:#364467 !important;
                color:white !important;
                div{
                    margin-right: 10px !important;
                    background: rgb(255, 255, 255) !important;
                    padding: 3px !important;
                    border-radius: 50% !important;
                    width: 22px !important;
                    height: 22px !important;
                    display: flex;
                }
            }
            p.copy{
                margin-top: 15px;
                // color: #666666;
                margin-bottom:0;
            }
        }
    }
    button:hover{
       color:black !important; 
       
    }
}

@media only screen and (max-width:480px){
    .outer-cont{
        width:100%;
        height:100vh;
        background-image: url('https://assets.solar.com/partner_assets/control-center/Hubble-bg.png');
        background-size: 202% 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-position: right;
        .login-container{
            margin: 40px 20px;
            h2{
            font-size:18px;
            }
        }
    } 
}